@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.FirstPart {
  margin-bottom: 50px;
}

.Title {
  font-size: 2em;
  font-weight: 900;
  text-align: left;
}

.Title2 {
  text-align: left;
  font-weight: 700;
  font-size: 1.1em;
  letter-spacing: 1px;
}

.Subtitle {
  color: grey;
  text-align: right;
  margin-top: 10px;
  font-style: italic;
}

.TopicTitle {
  display: inline-block;
  color: black;
  font-size: 1.2em;
  font-weight: 900;
}

.TopicTitle:hover {
  background-color: red;
  cursor: pointer;
  color: black;
  transition: 0.5s;
}

