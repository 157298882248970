.FirstPart {
  margin-bottom: 50px;
}

.Title {
  font-size: 2em;
  font-weight: 900;
  text-align: left;
}

.Title2 {
  text-align: left;
  font-weight: 700;
  font-size: 1.1em;
  letter-spacing: 1px;
}

.Subtitle {
  color: grey;
  text-align: right;
  margin-top: 10px;
  font-style: italic;
}

.TopicTitle {
  display: inline-block;
  color: black;
  font-size: 1.2em;
  font-weight: 900;
}

.TopicTitle:hover {
  background-color: red;
  cursor: pointer;
  color: black;
  transition: 0.5s;
}